import { DialogContent, Typography } from "@material-ui/core";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import globalColors from '../theme/colors';
import globalUseStyle from "../theme/customComponents";
import PropTypes from "prop-types";
import Radio from '@material-ui/core/Radio';
import React from "react";
import Table from '@material-ui/core/TableBody';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from "@material-ui/core/styles";


export function AddTest ({ onClose, open, heading, content, setContent }) {
  const globalClasses = globalUseStyle();

  return (
    <>
    <Dialog
      disableBackdropClick
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth=""
    >
      <DialogTitle id="simple-dialog-title">{heading}</DialogTitle>
      <DialogContent style={{ paddingBottom: "20px" }}>
            {SampleIdTable(content, setContent)}
        <DialogActions>
        <Button className={globalClasses.blueButton} variant="contained" onClick={onClose} style={{ width: "100px", marginLeft: "-2px" }}> Okay </Button>
        </DialogActions>
      </DialogContent>

    </Dialog>
    </>
  );
}


function SampleIdTable(sampleList, setContent){

    const [selectIdDialog, setSelectIdDialog] = React.useState(false);
    const [selectIdDialogContent, setSelectIdDialogContent] = React.useState([]);
    const [indexOfSample, setIndexOfSample] = React.useState();
    const [openList, setOpenList] = React.useState(false);

    React.useEffect(() => {
        if(indexOfSample != undefined && openList) {
            setSelectIdDialog(true)
            setOpenList(false)
        }
    }, [indexOfSample, openList])


    return (
        <>
        <TableContainer >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table aria-label="customized table" >
            <TableHead style={{ backgroundColor: "#E5EDFF" }}>
                <TableRow>
                    <TableCell>Episode ID</TableCell>
                    <TableCell>Test ID</TableCell>
                    <TableCell>Requesting Facility</TableCell>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Testing Facility</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {sampleList && sampleList.map((sample, i) => {
                return (
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align='center'> {sample?.nikshay_id} </TableCell>
                    <TableCell align='center'>
                    <Button style={{fontWeight: "400"}} onClick={() => {
                        // setSelectIdDialog(true)
                        setIndexOfSample(i)
                        setOpenList(true)
                        // setSelectIdDialogContent(sample)
                        }}>
                        {sample?.test_id} <ArrowDropDownIcon style={{ color: globalColors.textColor3, cursor: "pointer" }}/>
                    </Button>
                    </TableCell>
                    <TableCell align='center'>{sample?.requesting_facility}</TableCell>
                    <TableCell align='center'>{sample?.request_date}</TableCell>
                    <TableCell align='center'>{sample?.testing_facility}</TableCell>
                </TableRow> 
            )
            })}
            </TableBody>
        </Table>
        </div>
        </TableContainer>
        <SelectTestId 
            open={selectIdDialog}
            onClose={() => setSelectIdDialog(prev => !prev)}
            content={sampleList}
            setContent={setContent}
            index={indexOfSample} />
        
        </>
    )
}


export function SelectTestId({ onClose, open, content, setContent, index }){
    const globalClasses = globalUseStyle();
    const [selectedValue, setSelectedValue] = React.useState()

    React.useEffect(() => {
        if(selectedValue){
            const intValue = parseInt(selectedValue);
            
            if(intValue !== 100)
            {
                content[index].test_id = content[index].test_details.test_list[intValue].RequestId
                content[index].requesting_facility = content[index].test_details.test_list[intValue].RequestFacility
                content[index].request_date = content[index].test_details.test_list[intValue].RequestDate
                content[index].testing_facility = content[index].test_details.test_list[intValue].TestingFacility
                content[index].reason = content[index].test_details.test_list[intValue].Reason
                content[index].selected_index = intValue
                setContent([...content])
                setSelectedValue()
            }
            else{
                content[index].test_id = "New Test"
                content[index].requesting_facility = ""
                content[index].request_date = ""
                content[index].testing_facility = ""
                content[index].reason = ""
                content[index].selected_index = intValue
                setContent([...content])
                setSelectedValue()
            }
            
        }
    }, selectedValue)

    return (
        <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth=""
        >
        <DialogTitle id="simple-dialog-title"></DialogTitle>
        <DialogContent style={{ paddingBottom: "20px" }}>
                <SelectIdTable sampleDetail={content[index]?.test_details?.test_list}  setIndex={setSelectedValue} />
            <DialogActions>
            <Button className={globalClasses.blueButton} variant="contained" onClick={onClose} style={{ width: "100px", marginLeft: "-2px" }}> Okay </Button>
            </DialogActions>
        </DialogContent>

        </Dialog>
    );
}


export function SelectIdTable({sampleDetail, setIndex}){
    const [selectedValue, setSelectedValue] = React.useState();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        setIndex(event.target.value)
    };
    console.log("in table", sampleDetail)

    return (
        <>
        <TableContainer >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table aria-label="customized table" >
            <TableHead style={{ backgroundColor: "#E5EDFF" }}>
                <TableRow>
                    <TableCell>Test ID</TableCell>
                    <TableCell>Requesting Facility</TableCell>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Testing Facility</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {sampleDetail && sampleDetail.length > 0 && sampleDetail.map((sample, i) => {
                if(sample?.ResultId)
                    return (<> </>)
                return (
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align='center'> 
                    <Radio checked={selectedValue == i}
                        onChange={handleChange}
                        value={i}
                        name="radio-button-demo"
                        /> 
                        {sample.RequestId}
                    </TableCell>
                    <TableCell align='center'>{sample.RequestFacility}</TableCell>
                    <TableCell align='center'>{sample.RequestDate}</TableCell>
                    <TableCell align='center'>{sample.TestingFacility}</TableCell>
                </TableRow>
            )
            })}
            <TableRow key={"add_test"}>
                <TableCell component="th" scope="row" align='center'> 
                <Radio checked={selectedValue == 100}
                    onChange={handleChange}
                    value={100}
                    name="radio-button-demo"
                    /> 
                    Add as a new test
                </TableCell>
                <TableCell align='center'></TableCell>
                    <TableCell align='center'></TableCell>
                    <TableCell align='center'></TableCell>
            </TableRow>
            </TableBody>
        </Table>
        </div>
        </TableContainer>
        </>
    )
}


export function InvalidIdDialog ({ onClose, open, heading, content, setContent }) {
    const globalClasses = globalUseStyle();
  
    return (
      <Dialog
      disableBackdropClick
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth=""
      >
        <DialogTitle id="simple-dialog-title">{heading}</DialogTitle>
        <DialogContent style={{ paddingBottom: "20px" }}>
              {InvalidIdTable(content, setContent)}
          <DialogActions>
          <Button className={globalClasses.blueButton} variant="contained" onClick={onClose} style={{ width: "100px", marginLeft: "-2px" }}> Okay </Button>
          </DialogActions>
        </DialogContent>
  
      </Dialog>
    );
  }


function InvalidIdTable(sampleList, setContent){
    // const [selectedValue, setSelectedValue] = React.useState(
    //     sampleList.map((row) => {
    //         return 
    //     })
    // )

    function handleChange(index, value){
        sampleList[index].action = value
        setContent([...sampleList])
    }

    return (
        <>
        <TableContainer >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table aria-label="customized table" >
            <TableHead style={{ backgroundColor: "#E5EDFF" }}>
                <TableRow>
                    <TableCell style={{ minWidth: '100px' }}>Episode ID</TableCell>
                    <TableCell style={{ minWidth: '100px' }} align='center'>Status</TableCell>
                    <TableCell style={{ minWidth: '250px' }} align='center'>Action</TableCell>
                    <TableCell style={{ minWidth: '350px' }}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {sampleList.map((sample, i) => {
                return (
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align='center'> {sample.id} </TableCell>
                    <TableCell component="th" scope="row" align='center'> {sample.status === "invalid" ? "Invalid": "Outcome Assigned" } </TableCell>
                    <TableCell align='right'> 
                        <Radio checked={sample.action === "save_for_later"}
                            onChange={() => handleChange(i, "save_for_later")}
                            value={sample.id}
                            name="radio-button-demo"
                            />  
                            Save to infer later 
                        </TableCell>
                    <TableCell align='left'>
                        <Radio checked={sample.action === "delete"}
                                onChange={() => handleChange(i, "delete")}
                                value={sample.id}
                                name="radio-button-demo"
                                />  
                        Delete 
                    </TableCell>
                </TableRow>
            )
            })}
            </TableBody>
        </Table>
        </div>
        </TableContainer>
        </>
    )
}
