import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getDistricts, getLabs, getNikshayIds, getSampleInfo, getStates, getSummaryData, putSampleFinalSubmission, putSampleinfo } from "../actions/global";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AppBar from "../components/appBar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BreadCrumbList from "../components/breadCrumbList";
import Button from "@material-ui/core/Button";
import { ButtonGroup } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
// import FormLabel from '@material-ui/core/FormLabel';
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import globalColors from "../theme/colors"
import globalUseStyle from "../theme/customComponents";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";

// import { logUserDetails } from "../analytics/analyticsHelper";
// import moment from "moment";

const CssTextField = withStyles({
    root: {
        backgroundColor: "white",
      "& label.Mui-focused": {
        color: "#DADCE0",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#DADCE0",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
        "&:hover fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        },
      },
    },
  })(TextField);

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    leftPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 360,
    },
    paper: {
        padding: theme.spacing(2),
        elevation: "0",
        display: "flex",
        flexDirection: "column",
        background: "#F8F9FA",
        height: "100%",
    },
    container: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    dashboardContainer: {
        padding: 20,
    },
    nextStepButton: {
        display: "flex",
        paddingTop: 30,
        flexDirection: "column",
        alignItems: "center",
        "& .MuiButton-contained.Mui-disabled": {
            backgroundColor: "white"
        }
    },
    pagination: {
        display: "flex",
        paddingTop: 30,
        flexDirection: "column",
        alignItems: "center",
    },
    prevNextButton: {
        display: "flex",
        paddingTop: 30,
        flexDirection: "column",
        alignItems: "center",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "400",
        fontStyle: "normal",
        
        "& .MuiButton-contained.Mui-disabled": {
            backgroundColor: globalColors.brandColor1
        }
    },
    rightSideDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 280,
    },
    c1: {
        paddingTop: 5,
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#1967D2"),
        fontWeight: "500",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "18px",
        backgroundColor: "#1967D2",
        "&:hover": {
        backgroundColor: "#1967D2",
        "@media (hover: none)": {
            backgroundColor: "#1967D2",
        },
        },
    },
    prevNextbutton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#ffffff"),
        fontWeight: "400",
        // backgroundColor: "#ffffff",
        // "&:hover": {
        // backgroundColor: "#ffffff",
        // "@media (hover: none)": {
        //     backgroundColor: "#ffffff",
        // },
        // },
        color: theme.palette.getContrastText("#1967D2"),
        backgroundColor: globalColors.brandColor1,
        "&:hover": {
          backgroundColor: globalColors.brandColor1,
          "@media (hover: none)": {
            backgroundColor: globalColors.brandColor1,
          },
        },
        "&:disabled": {
            backgroundColor: globalColors.brandColor1,
            disabledBackground: globalColors.brandColor1,
            opacity: 0.3,
            color: theme.palette.getContrastText(globalColors.brandColor1)
          },
    },
    interpretSheetsGuideText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "400",
    },
    interpretGuideGrid: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        width: "64px",
    },
    inputField: {
        backgroundColor: "white",
    }
}));
const SampleInformationCard = ({ setState }) => {
    const classes = useStyles();
    const globalClasses = globalUseStyle();

    const pageListLT = ['Upload', 'Enter Nikshay IDs', 'Check Controls', 'Review Interpretation', 'Summary', 'Sample Information']
    const pageListMB = ['Check Controls', 'Review Interpretation', 'Summary', 'Sample Information']

    const [mbReview, setMbReview] = useState(false)
    const [sampleData, setSampleData] = useState({})
    const [sampleNikshayIds, setSampleNikshayIds] = useState([])
    const [currentSheet, setCurrentSheet] = useState(1)
    const [disablePrevButton, setDisablePrevButton] = useState(true)
    const [disableNextButton, setDisableNextButton] = useState(false)

    const [selectedReasonTest, setSelectedReasonTest] = useState("")
    const [selectedTypeOfPresumptiveDRTB, setSelectedTypeofPresumptiveDRTB] = useState("")
    const [selectedHistoryPreviousATT, setSelectedHistoryPreviousATT] = useState("")
    const [selectedSampleType, setSelectedSampleType] = useState("Sputum")
    const [selectedSampleDescription, setSelectedSampleDescription] = useState("")
    const [selectedState, setSelectedState] = useState({})
    const [selectedDistrict, setSelectedDistrict] = useState({})
    const [selectedLab, setSelectedLab] = useState({})
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedTempTime, setSelectedTempTime] = useState("")
    const [selectedTime, setSelectedTime] = useState("")

    const [errorReasonTest, setErrorReasonTest] = useState(false)
    const [errorHistoryPreviousATT, setErrorHistoryPreviousATT] = useState(false)
    const [errorSampleType, setErrorSampleType] = useState(false)
    const [errorSampleDescription, setErrorSampleDescription] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [errorDistrict, setErrorDistrict] = useState(false)
    const [errorLab, setErrorLab] = useState(false)
    const [errorDate, setErrorDate] = useState(false)
    const [errorTime, setErrorTime] = useState(false)

    const reasonsForTesting = ["Diagnosis of DRTB", "Follow up of DRTB"]
    const typesOfPresumptiveDRTB = ["At Diagnosis", "Contact of MDR/RRTB", "Any Follou-up +ve", "Private Referral", "Presumptive H Mono/Poly", "MDR/RRTB at Diagnosis", "Discordance Resolution", "Failure of recurrance of MDR/RR-TB regimen", "Follow up culture Positive"]
    const sampleTypes = ["Sputum", "Other"]
    const sampleDescriptions = ["Mucopurulent", "Blood Stained", "Saliva"]
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [labs, setLabs] = useState([])
    const dispatch = useDispatch();
    const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
    useEffect(() => {
        dispatch(getNikshayIds(batchId)).then((res) => { setSampleNikshayIds(res?.list_of_ids); setMbReview(res?.mb_review) })
        // makeGetApiCall(currentSheet)
    }, [dispatch, batchId])
    useEffect(() => {
        if (sampleNikshayIds?.length > 0) { makeGetApiCall(currentSheet); handleDisable(1); }
    }, [sampleNikshayIds])
    useEffect(() => {
        dispatch(getStates()).then((res) => { setStates(res.list_of_states) })
    }, [dispatch])
    useEffect(() => {
        if (selectedState?.state_id) {
            dispatch(getDistricts(selectedState)).then((res) => { setDistricts(res?.list_of_districts === undefined ? [] : res?.list_of_districts) })
        }
    }, [dispatch, selectedState])
    useEffect(() => {
        if (selectedDistrict?.district_id) { dispatch(getLabs(selectedDistrict)).then((res) => { setLabs(res?.list_of_labs === undefined ? [] : res?.list_of_labs) }) }
    }, [selectedState, selectedDistrict, dispatch])
    function handleBack () {
        dispatch(getSummaryData(batchId)).then(() => setState("s"));
    }

    function handleDisable (val) {
        if (val <= 1) {
            setDisablePrevButton(true)
            if (sampleNikshayIds.length === 1)
                setDisableNextButton(true)
            else
                setDisableNextButton(false)
        } else if (val >= sampleNikshayIds.length) {
            setDisableNextButton(true)
            setDisablePrevButton(false)
        } else {
            setDisableNextButton(false)
            setDisablePrevButton(false)
        }
    }
    function makePutApiCall (sheetID) {
        var newData = null
        if (selectedReasonTest !== "Diagnosis of DRTB") {
            newData = {
                nikshay_id: sampleNikshayIds[sheetID - 1],
                reason_for_testing: selectedReasonTest,
                sample_type: selectedSampleType,
                sample_description: selectedSampleDescription,
                collection_site_state_id: selectedState.state_id,
                collection_site_district_id: selectedDistrict.id,
                collection_site_lab_id: selectedLab.id,
                collection_site_state_name: selectedState.name,
                collection_site_district_name: selectedDistrict.name,
                collection_site_lab_name: selectedLab.name,
                collection_timestamp: selectedDate + " " + selectedTime + (selectedTime?.length > 4 ? "" : ":00"),
            }
        } else {
        newData = {
            nikshay_id: sampleNikshayIds[sheetID - 1],
            reason_for_testing: selectedReasonTest,
            type_of_presumptive_DRTB: selectedTypeOfPresumptiveDRTB,
            history_of_previous_ATT: selectedHistoryPreviousATT,
            sample_type: selectedSampleType,
            sample_description: selectedSampleDescription,
            collection_site_state_id: selectedState.state_id,
            collection_site_district_id: selectedDistrict.id,
            collection_site_lab_id: selectedLab.id,
            collection_site_state_name: selectedState.name,
            collection_site_district_name: selectedDistrict.name,
            collection_site_lab_name: selectedLab.name,
            collection_timestamp: selectedDate + " " + selectedTime + (selectedTime?.length > 4 ? "" : ":00"),
        }
        }
        dispatch(putSampleinfo(batchId, newData)).then(() => {})
        // console.log(newData)
        var nikshayID = sampleNikshayIds[sheetID - 1];
        sampleData[nikshayID] = newData
        // console.log(sampleData)
        setSampleData(sampleData)
        setSelectedReasonTest("")
        setSelectedTypeofPresumptiveDRTB("")
        setSelectedHistoryPreviousATT("")
        setSelectedSampleType("Sputum")
        setSelectedSampleDescription("Mucopurulent")
        setSelectedState({})
        setSelectedDistrict({})
        setSelectedLab({})
        setSelectedDate("")
        setSelectedTime("")
    }
    function makeGetApiCall (sheetID) {
        var nikshayID = sampleNikshayIds[sheetID - 1];
        dispatch(getSampleInfo(batchId, nikshayID)).then((res) => {
            console.log("response ", res)
            setSelectedReasonTest(res.reason_for_testing === null ? "" : res.reason_for_testing)
            setSelectedTypeofPresumptiveDRTB(res.type_of_presumptive_DRTB === null ? "" : res.type_of_presumptive_DRTB)
            setSelectedHistoryPreviousATT(res.history_of_previous_ATT === null ? "" : res.history_of_previous_ATT === true ? "Yes" : "No")
            setSelectedSampleType(res.sample_type === null ? "Sputum" : res.sample_type)
            setSelectedSampleDescription(res.sample_description === null ? "Mucopurulent" : res.sample_description)
            setSelectedState(res.collection_site_state === null ? {} : res.collection_site_state)
            setSelectedDistrict(res.collection_site_district === null ? {} : res.collection_site_district)
            setSelectedLab(res.collection_site_lab === null ? {} : res.collection_site_lab)
            setSelectedDate(res.collection_timestamp === null ? "" : res.collection_timestamp.split("T")[0])
            setSelectedTime(res.collection_timestamp === null ? "" : res.collection_timestamp.split("T")[1].slice(0, 5))
         })
    }

    function checkRequirement () {
        var flag = true
        if (selectedReasonTest === "") {
            setErrorReasonTest(true)
            flag = false
        }
        if (selectedHistoryPreviousATT === "" && selectedReasonTest === "Diagnosis of DRTB") {
            setErrorHistoryPreviousATT(true)
            flag = false
        }
        if (selectedSampleType === "") {
            setErrorSampleType(true)
            flag = false
        }
        if (selectedSampleDescription === "") {
            setErrorSampleDescription(true)
            flag = false
        }
        if (_.isEqual(selectedState, {})) {
            setErrorState(true)
            flag = false
        }
        if (_.isEqual(selectedDistrict, {})) {
            setErrorDistrict(true)
            flag = false
        }
        if (_.isEqual(selectedLab, {})) {
            setErrorLab(true)
            flag = false
        }
        if (selectedDate === "") {
            setErrorDate(true)
            flag = false
        }
        if (selectedTime === "") {
            setErrorTime(true)
            flag = false
        }
        return flag
    }
    function removeError () {
        setErrorReasonTest(false)
        setErrorHistoryPreviousATT(false)
        setErrorSampleType(false)
        setErrorSampleDescription(false)
        setErrorState(false)
        setErrorDistrict(false)
        setErrorLab(false)
        setErrorDate(false)
        setErrorTime(false)
    }
    function handleNextPage () {
        var requirementCheck = checkRequirement()
        if (requirementCheck === true) {
            removeError()
            makePutApiCall(currentSheet)
            setCurrentSheet(currentSheet + 1)
            handleDisable(currentSheet + 1)
            makeGetApiCall(currentSheet + 1)
        }
    }
    function handlePreviousPage () {
        removeError()
        makePutApiCall(currentSheet)
        setCurrentSheet(currentSheet - 1)
        handleDisable(currentSheet - 1)
        makeGetApiCall(currentSheet - 1)
    }
    // function handleStateChange (event) {
    //     var value = event.target.value;
    //     setSelectedState(value)
    // }
    function handleNext () {
        var requirementCheck = checkRequirement()
        if (requirementCheck === true) {
            removeError()
            makePutApiCall(currentSheet)
            dispatch(putSampleFinalSubmission(batchId)).then(() => {})
            setState("d")
        }
    }

    function handleSelectedState (e) {
        var tempState = e.target.value
        for (var i = 0; i < states.length; i++) {
            if (states[i].name === tempState) {
                var tempStateId = states[i].state_id
                setSelectedState({ name: tempState, state_id: tempStateId })
                break
            }
          }
    }

    function handleSelectedDistrict (e) {
        var tempDistrict = e.target.value
        for (var i = 0; i < districts.length; i++) {
            if (districts[i].name === tempDistrict) {
                var tempDistrictId = districts[i].district_id
                setSelectedDistrict({ name: tempDistrict, district_id: tempDistrictId, id: districts[i].id })
                break
            }
          }
    }

    function handleSelectedLab (e) {
        var tempLab = e.target.value
        for (var i = 0; i < labs.length; i++) {
            if (labs[i].name === tempLab) {
                var tempLabId = labs[i].lab_id
                setSelectedLab({ name: tempLab, lab_id: tempLabId, id: labs[i].id })
                break
            }
          }
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar mbReview={false}/>
      <Grid
        container
        className={classes.dashboardContainer}
        style={{ background: "white" }}
      >
      <BreadCrumbList pageList={mbReview ? pageListMB : pageListLT} setState={setState} warningMessage={null} />
        <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer", marginLeft: "15px", marginTop: "20px" }}></ArrowBackIcon>
        <Typography
          style={{
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
            paddingLeft: "20px",
            paddingBottom: "20px",
            paddingTop: "20px"
          }}
        >
          Enter Sample Information
        </Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        className={classes.container}
        style={{ background: "white" }}
      >
            <Grid item xs={12} md={12} lg={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Grid
                    container
                    spacing={2}
                    style={{ paddingLeft: "30px", paddingRight: "30px" }}
                    >
                        <Grid
                            item
                            style={{
                            display: "flex",
                            paddingBottom: "20px",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            width: "100%",
                            }}
                        >
                            <Typography
                            style={{
                                paddingTop: "10px",
                                width: "200px",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "24px",
                                textAlign: "left",
                            }}
                            >
                            {sampleNikshayIds[currentSheet - 1]}
                            </Typography>
                        </Grid>
                        <Grid
                        container
                        spacing={10}
                        style={{ padding: "30px" }}
                        >
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Reason for testing
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorReasonTest}>
                                <InputLabel >Select Reason</InputLabel>
                                    <Select
                                        key={selectedReasonTest}
                                        defaultValue=""
                                        value={selectedReasonTest}
                                        label="Select Reason"
                                        onChange={(e) => setSelectedReasonTest(e.target.value)}
                                    >
                                        {
                                            reasonsForTesting.map((reason, i) =>
                                            <MenuItem key={reason} value={reason} selected={reason === selectedReasonTest }>{reason}</MenuItem>)
                                        }
                                    </Select>
                                    {errorReasonTest && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {selectedReasonTest === "Diagnosis of DRTB" && <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Type of Presumptive DRTB
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField}>
                                <InputLabel >Select Type</InputLabel>
                                    <Select
                                        defaultValue=""
                                        key={selectedTypeOfPresumptiveDRTB}
                                        value={selectedTypeOfPresumptiveDRTB}
                                        label="Select Type"
                                        onChange={(e) => setSelectedTypeofPresumptiveDRTB(e.target.value)}
                                    >
                                        {
                                            typesOfPresumptiveDRTB.map((type, i) =>
                                            <MenuItem key={type} value={type} selected={ type === selectedTypeOfPresumptiveDRTB }>{type}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>}
                            { selectedReasonTest === "Diagnosis of DRTB" && <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    History for Previous ATT
                                </Typography>
                                <FormControl error={errorHistoryPreviousATT}>
                                    <RadioGroup
                                        row
                                        key={selectedHistoryPreviousATT}
                                        onChange={(e) => setSelectedHistoryPreviousATT(e.target.value)}
                                    >
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" checked={ selectedHistoryPreviousATT === "Yes" }/>
                                        <FormControlLabel value="No" control={<Radio />} label="No" checked={ selectedHistoryPreviousATT === "No" }/>
                                    </RadioGroup>
                                    {errorHistoryPreviousATT && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>}
                        </Grid>
                        <Grid
                        container
                        spacing={10}
                        style={{ padding: "30px" }}
                        >
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Sample Type
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorSampleType}>
                                {/* <InputLabel >Select Sample Type</InputLabel> */}
                                    <Select
                                        defaultValue=""
                                        value={selectedSampleType}
                                        key={selectedSampleType}
                                        // label="Select Sample Type"
                                        onChange={(e) => setSelectedSampleType(e.target.value)}
                                    >
                                        {
                                            sampleTypes.map((sampleType, i) =>
                                            <MenuItem key={sampleType} value={sampleType} selected={ sampleType === selectedSampleType }>{sampleType}</MenuItem>)
                                        }
                                    </Select>
                                    {errorSampleType && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Sample Description
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorSampleDescription}>
                                {/* <InputLabel >Select Sample Description</InputLabel> */}
                                    <Select
                                        defaultValue=""
                                        value={selectedSampleDescription}
                                        key={selectedSampleDescription}
                                        // label="Select Sample Description"
                                        onChange={(e) => setSelectedSampleDescription(e.target.value)}
                                    >
                                        {
                                            sampleDescriptions.map((sampleDescription, i) =>
                                            <MenuItem key={sampleDescription} value={sampleDescription} selected={ sampleDescription === selectedSampleDescription }>{sampleDescription}</MenuItem>)
                                        }
                                    </Select>
                                    {errorSampleDescription && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                        container
                        spacing={10}
                        style={{ padding: "30px" }}
                        >
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Collection Site State
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorState}>
                                <InputLabel >Select State</InputLabel>
                                    <Select
                                        defaultValue=""
                                        label="Select State"
                                        value={selectedState.name}
                                        key={selectedState.name}
                                        onChange={handleSelectedState}
                                    >
                                        {
                                            states.map((stateLis, i) =>
                                            <MenuItem key={stateLis.name} value={stateLis.name} selected={ stateLis.name === selectedState.name } >{stateLis.name}</MenuItem>)
                                        }
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Collection Site District
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorDistrict}>
                                <InputLabel >Select District</InputLabel>
                                    <Select
                                        defaultValue=""
                                        value={selectedDistrict.name}
                                        label="Select District"
                                        key={selectedDistrict.name}
                                        onChange={handleSelectedDistrict}
                                    >
                                        {
                                            districts.map((district, i) =>
                                            <MenuItem key={district.name} value={district.name} selected={ district.name === selectedDistrict.name }>{district.name}</MenuItem>)
                                        }
                                    </Select>
                                    {errorDistrict && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Collection Site Lab
                                </Typography>
                                <FormControl fullWidth variant="outlined" className={classes.inputField} error={errorLab}>
                                <InputLabel >Select Lab</InputLabel>
                                    <Select
                                        defaultValue=""
                                        value={selectedLab.name}
                                        label="Select Lab"
                                        key={selectedLab.name}
                                        onChange={handleSelectedLab}
                                    >
                                        {
                                            labs.map((lab, i) =>
                                            <MenuItem key={lab.name} value={lab.name} selected={ lab.name === selectedLab.name }>{lab.name}</MenuItem>)
                                        }
                                    </Select>
                                    {errorLab && <FormHelperText>This is required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                        container
                        spacing={10}
                        style={{ padding: "30px" }}
                        >
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Collection Date
                                </Typography>
                                <CssTextField
                                    key={selectedDate}
                                    error={errorDate}
                                    fullWidth
                                    id="date"
                                    type="date"
                                    // size="small"
                                    variant="outlined"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    className={classes.textField}
                                    // style={{ width: "208px" }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    max: new Date().toISOString().split("T")[0],
                                    }}
                                    helperText={errorDate ? "This is required!" : ""}
                                />
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        cursor: "pointer"
                                    }}
                                    // onClick={(e) => setSelectedTime(selectedTempTime)}
                                    >
                                        .
                                </Typography>
                            </Grid>
                            <Grid lg={3}
                            item
                            style={{
                                display: "flex",
                                paddingBottom: "20px",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingTop: "10px",
                                        width: "200px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    >
                                    Collection Time
                                </Typography>
                                <CssTextField
                                    error={errorTime}
                                    key={selectedTime}
                                    fullWidth
                                    id="time"
                                    type="time"
                                    // size="small"
                                    variant="outlined"
                                    value={selectedTime}
                                    onChange={(e) => setSelectedTempTime(e.target.value)}
                                    className={classes.textField}
                                    // style={{ width: "100%" }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    max: new Date().toISOString().split("T")[0],
                                    }}
                                    helperText={errorTime ? "This is required!" : ""}
                                />
                                <Button
                                    className={classes.button}
                                    onClick={(e) => setSelectedTime(selectedTempTime)}
                                    >
                                    Set Time
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
            <div className={classes.pagination} style={{ background: "white" }}>
                <Typography
                    style={{
                        paddingTop: "10px",
                        width: "400px",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#2B78C2"
                    }}
                    >
                    {currentSheet}/{sampleNikshayIds.length}
                </Typography>
        </div>
        <div className={classes.prevNextButton} style={{ background: "white" }}>
            <ButtonGroup disableElevation variant="contained">
            <Button disabled={disablePrevButton} onClick={handlePreviousPage} className={classes.prevNextbutton} endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                Previous Sample
            </Button>
            <Button disabled={disableNextButton} onClick={handleNextPage} className={classes.prevNextbutton} startIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
                Next Sample
            </Button>
            </ButtonGroup>
        </div>
        {/* TODO: Disbale till you fill this all */}
        <div className={classes.nextStepButton} style={{ background: "white" }}>
            <Button disabled={currentSheet !== sampleNikshayIds.length} onClick={handleNext} className={globalClasses.blueButton} variant="contained" endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
            {
                mbReview
                ? "Submit"
                : "Share with Microbiologist for review"
            }
            </Button>
      </div>
        </React.Fragment>
    )
}

export default SampleInformationCard;
