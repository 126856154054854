import {
  SET_BATCH_ID,
  SET_USER_TYPE,
  SET_BATCH_INFO_FOR_DASHBOARD,
  SET_BATCH_ROI,
  SET_BATCH_VALIDITY,
  SET_INTERPRETATION,
  SET_QUALITY_CHECK,
  SET_QUALITY_CHECK_ERROR,
  SET_SUMMARY,
  SET_UPLOADED_SHEETS,
  SET_USER_INFO,
  SET_DEMO,
  SET_USER_ID,
  SET_AUTHENTICATED,
  SET_LOGIN_URL,
  SET_ALL_SAMPLE_INFO
} from "./types";
import { batchApi, dashboardApi, districtApi, hierarchyApi, interpretationApi, labApi, sampleInfoApi, sheetApi, stateApi, submitApi, submitExistingApi, userApi } from "../env";
import fetchApi from "./fetchApi"

export function setDemo (data) {
  return {
    type: SET_DEMO,
    data
  };
}

export function setUserType (data) {
  return {
    type: SET_USER_TYPE,
    data
  };
}

export function setUserId (data) {
  return {
    type: SET_USER_ID,
    data
  };
}

export function setBatchId (data) {
  return {
    type: SET_BATCH_ID,
    data
  };
}

export function setQualityCheck (data) {
  return {
    type: SET_QUALITY_CHECK,
    data
  };
}

export function setQualityCheckError (data) {
  return {
    type: SET_QUALITY_CHECK_ERROR,
    data
  };
}

export function setSheetsInBatch (data) {
  return {
    type: SET_UPLOADED_SHEETS,
    data
  };
}

export function setBatchRoi (data) {
  return {
    type: SET_BATCH_ROI,
    data
  };
}

export function setInterpretation (data) {
  return {
    type: SET_INTERPRETATION,
    data
  };
}

export function setBatchValidity (data) {
  return {
    type: SET_BATCH_VALIDITY,
    data
  };
}

export function setSummaryData (data) {
  return {
    type: SET_SUMMARY,
    data
  };
}

export function setUserInfo (data) {
  return {
    type: SET_USER_INFO,
    data
  };
}

export function setBatchInfoForDashboard (data) {
  return {
    type: SET_BATCH_INFO_FOR_DASHBOARD,
    data
  };
}

export function setAuhtenticated(data) {
  return {
    type: SET_AUTHENTICATED,
    data
  };
}

export function setLoginUrl(data) {
  return {
    type: SET_LOGIN_URL,
    data
  };
}


export function setAllSampleInfo(data) {
  return {
    type: SET_ALL_SAMPLE_INFO,
    data
  };
}


function fetchWrapper(dispatch, ...args){
  var response = fetchApi(...args)
  response.then((res) => {
    console.log("in wrapper")
    if(res?.data?.login_url  && res?.data?.login_url !== '')
    {
      console.log("if statement")
      console.log(res?.data?.login_url)
      dispatch(setAuhtenticated(false))
      dispatch(setLoginUrl(res?.data?.login_url))
    }
    else
    {
      console.log("else statement")
      dispatch(setAuhtenticated(true))
      dispatch(setLoginUrl(''))
    }
    });
  
  return response
}


export function createBatch (batchType, userType) {
  var formdata = new FormData();
  formdata.append("user_type", userType);
  formdata.append("batch_type", batchType);
  return dispatch => {
    return fetchApi(batchApi, "POST", formdata)
      .then(res => {
        dispatch(setBatchId(res?.data));
      });
  };
}

export function deleteBatch (params = {}) {
  return () => {
    return fetchApi(batchApi, "DELETE", params)
      .then(res => {
        // console.log(res?.data)
      });
  };
}

export function putMbForBatch (batchId) {
  return () => {
    return fetchApi(batchApi + 'review/' + batchId, "PUT")
      .then(() => {
      });
  };
}

export function getQualityCheck (batchId, image) {
  var formdata = new FormData();
  formdata.append("batch_id", batchId);
  formdata.append("image", image)
  return dispatch => {
    return fetchApi(sheetApi, "POST", formdata)
      .then(res => {
        res?.success ? dispatch(setQualityCheck(res?.data)) : dispatch(setQualityCheckError(res?.detail));
      });
  };
}

export function deleteSheet (params = {}) {
  return () => {
    return fetchApi(sheetApi, "DELETE", params)
      .then(res => {
        // console.log(res?.data)
      });
  };
}

export function submitSheet (sheetId) {
  return () => {
    return fetchApi(sheetApi + sheetId, "PUT")
      .then(() => {
        // console.log(res?.data)
      });
  };
}

export function reportSheet (sheetId, reportReason) {
  var formdata = new FormData();
  formdata.append("report_reason", reportReason);
  return () => {
    return fetchApi(sheetApi + 'report/' + sheetId, "PUT", formdata)
      .then(() => {
        // console.log(res?.data)
      });
  };
}

export function getSheetsInBatch (batchId) {
  return dispatch => {
    return fetchApi(batchApi + "sheets/" + batchId, "GET")
      .then(res => {
        dispatch(setSheetsInBatch(res?.data));
      });
  };
}

export function getBatchRoi (batchId) {
  return dispatch => {
    return fetchApi(batchApi + batchId, "GET")
      .then(res => {
        dispatch(setBatchRoi(res?.data));
      });
  };
}

export function checkNikshayId (labIds, batchId) {
  return dispatch => {
    return fetchApi(batchApi + "check-nikshay/" + batchId, "POST", JSON.stringify({ lab_sample_id_list: labIds }), {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
        return res?.data;
      });
  };
}


export function getTestForSample (refIds, batchId) {
  return dispatch => {
    return fetchApi(batchApi + "get-test/" + batchId, "POST", JSON.stringify({ list_of_ref_ids: refIds }), {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
        return res?.data;
      });
  };
}



export function getRefForSample (labIds, batchId) {
  return dispatch => {
    return fetchApi(batchApi + "get-ref/" + batchId, "POST", JSON.stringify({ lab_sample_id_list: labIds }), {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
        return res?.data;
      });
  };
}


export function updateTestForSample (testList, batchId) {
  return dispatch => {
    return fetchApi(batchApi + "update-test/" + batchId, "POST", JSON.stringify({ lab_sample_id_list: testList }), {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
      console.log("only res ", res)
      console.log("checking IDs ", res?.data)
        return res?.data;
      });
  };
}



export function submitLabIdsForBatch (batchId, sheets) {
  
  const batchRoi = async () => {
    const request = await fetchApi(batchApi + batchId, "POST", JSON.stringify({ sheets: sheets }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  const batchValidity = async () => {
    const request = await fetchApi(batchApi + 'validate/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };
    return async dispatch => {
      await batchRoi();
      const data = await batchValidity();
      dispatch(setBatchValidity(data?.data));
      return data?.data
    };
  };

  export function getBatchValidity (batchId) {
    return dispatch => {
      return fetchApi(batchApi + 'validate/' + batchId, "GET")
        .then(res => {
          dispatch(setBatchValidity(res?.data));
        });
    };
  }

export function getInterpretation (batchId, updatedRows) {
  const batchValidityPut = async () => {
    const request = await fetchApi(interpretationApi + batchId, "PUT", JSON.stringify({ updated_rows: updatedRows }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  const getInterpretationApi = async () => {
    const request = await fetchApi(interpretationApi + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

    return async dispatch => {
      await batchValidityPut();
      const data = await getInterpretationApi();
      dispatch(setInterpretation(data?.data));
    };
}

export function getInterpretationData (batchId) {
  return dispatch => {
    return fetchApi(interpretationApi + batchId, "GET")
      .then(res => {
        dispatch(setInterpretation(res?.data));
      });
  };
}

export function toggleFlag (rowId) {
  return () => {
    return fetchApi(interpretationApi + 'flag/' + rowId, "PUT")
    .then(() => {
      //
    });
  }
}

export function getSummaryData (batchId) {
  return dispatch => {
    return fetchApi(batchApi + 'summary/' + batchId, "GET")
      .then(res => {
        dispatch(setSummaryData(res?.data));
      });
  };
}

export function getSummary (batchId, updatedRows, makeCall) {
  const interpretationPut = async () => {
    const request = await fetchApi(interpretationApi + batchId, "PUT", JSON.stringify({ updated_rows: updatedRows }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  const getSummaryApi = async () => {
    const request = await fetchApi(batchApi + 'summary/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

    return async dispatch => {
      if (makeCall) {
        await interpretationPut()
      }
      const data = await getSummaryApi();
      dispatch(setSummaryData(data?.data));
    };
}

export function submitInterpretation (batchId, updatedRows) {
  const submitInterpretationApi = async () => {
     const request = await fetchApi(interpretationApi + 'batch/' + batchId, "PUT", JSON.stringify({ updated_rows: updatedRows }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };
  const getSummaryApi = async () => {
    const request = await fetchApi(batchApi + 'summary/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  return async dispatch => {
    await submitInterpretationApi()
    const data = await getSummaryApi();
    dispatch(setSummaryData(data?.data));
  };
}

export function getUserInfo (userType) {
  
  return dispatch => {
    return fetchWrapper(dispatch, userApi + userType, "GET").then(res => {
        dispatch(setUserInfo(res?.data));
      });
  };
}

export function getBatchInfoForDashboard (userType) {
  return dispatch => {
    return fetchApi(dashboardApi + userType, "GET")
      .then(res => {
        // if(res?.data?.login_url  && res?.data?.login_url !== '')
        // dispatch(setLoginUrl(res?.data?.login_url))
        dispatch(setBatchInfoForDashboard(res?.data));
      });
  };
}


export function getPendingRows () {
  return dispatch => {
    return fetchApi(dashboardApi + "pending/", "GET")
      .then(res => {
        return res?.data
      });
  };
}




export function getAllSampleInfo (batchId) {
  return dispatch => {
    return fetchApi(sampleInfoApi + "all/" + batchId + "/", "GET")
      .then(res => {
        dispatch(setAllSampleInfo(res?.data));
      });
  };
}

export function getHierarchies (batchId) {
  return dispatch => {
    return fetchApi(hierarchyApi + batchId, "GET")
      .then(res => {
        return res?.data
      });
  };
}


export function submitAPI(batchId){
  return dispatch => {
    return fetchApi(submitApi + batchId, "GET")
      .then(res => {
        return res?.data
      });
  };
}

export function submitPendingRows(labIds, batchId ){
  return dispatch => {
    return fetchApi(batchApi + "pending/" + batchId + "/", "POST", JSON.stringify({ list_of_pending_rows: labIds }), {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }).then(res => {
        return res?.data;
      });
  };
}




export function getStates () {
  return dispatch => {
    return fetchApi(stateApi, "GET")
      .then(res => {
        return res?.data
      });
  };
}

export function getDistricts (state) {
  if (state === {}) {
    return { list_of_districts: [] }
  }
  return dispatch => {
    return fetchApi(districtApi + state?.state_id, "GET")
      .then(res => {
        return res?.data
      });
  };
}

export function getLabs (district) {
  if (district === {}) {
    return { list_of_labs: [] }
  }
  return dispatch => {
    return fetchApi(labApi + district?.district_id, "GET")
      .then(res => {
        return res?.data
      });
  };
}

export function getNikshayIds (batchId) {
  return dispatch => {
    return fetchApi(sampleInfoApi + "list/" + batchId, "GET")
      .then(res => {
        return res?.data
      });
  };
}

export function getSampleInfo (batchId, nikshayID) {
  return dispatch => {
    return fetchApi(sampleInfoApi + batchId + "/" + nikshayID, "GET")
      .then(res => {
        return res?.data
      });
  };
}


export function putSampleinfo (batchId, data) {
  return () => {
    return fetchApi(sampleInfoApi + batchId, "PUT", JSON.stringify(data), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
      .then((res) => {
        // console.log(res?.data)
      });
  };
}

export function putExistingSampleinfo (batchId, data) {
  console.log("existing ", data)
  return () => {
    return fetchApi(submitExistingApi + batchId, "PUT", JSON.stringify({"sample_list": data}), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
      .then((res) => {
        // console.log(res?.data)
      });
  };
}

export function putSampleFinalSubmission (batchId) {
  return dispatch => {
    return fetchApi(sampleInfoApi + "submit/" + batchId, "PUT")
      .then(res => {
        // console.log(res?.data)
      });
  };
}
